import { isEmpty, merge } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import RailsVars from '~/apps/railsVariables.js.erb';
import {
  gtmTriggers,
  ga4Events,
  customEventGa4,
  pagePathQueryFragment,
  ttqEvents,
  ttqEventGa4
} from '~/utils/analytics/gtm';
import { ahoyEvents, ahoyTrack } from '~/utils/analytics/ahoy';
import { amplitudeTrack } from '~/utils/analytics/amplitude';

const RESPONSE_PROVIDERS = {
  account_wishlist: 'account_wishlist',

  list_hipvan: 'hipvan',
  list_klevu: 'klevu',
  list_klevu_hv: 'klevu_hv',
  list_extend: 'extend',

  item: 'hipvan',
  item_extend: 'extend',
  item_swatches: 'swatches'
};

const ahoyTrackPageView = ({
  pageType,
  treeLevel = null,
  productArchetype = null
} = {}) =>
  ahoyTrack({
    eventType: globalThis.HV.HIT_TYPES.view,
    eventData: {
      page_type: pageType,
      tree_level: treeLevel,
      product_archetype: productArchetype
    }
  });

const trackShare = ({
  method,
  url,
  idValue,
  description,
  contentType = 'unknown_trackShare',
  location = 'unknown_trackShare'
} = {}) => {
  const eventNameKey = 'share';
  const ga4Payload = {
    mode: method,
    title: contentType,
    item_id: idValue,
    url,
    trigger: description,
    location
  };
  customEventGa4(gtmTriggers?.userShare, ga4Events?.[eventNameKey], ga4Payload);
  amplitudeTrack(ga4Events?.[eventNameKey], ga4Payload);
  ahoyTrack({
    eventType: globalThis.HV.HIT_TYPES.custom,
    eventData: {
      event: ahoyEvents?.[eventNameKey],
      trackableType: method,
      trackableId: idValue,
      trackableUrl: url
    }
  });
};

// --------------------
// Product Interest
// --------------------

const trackBrokenImage = ({ label, itemLocation, listPosition, mode } = {}) => {
  if (process.env.RAILS_ENV !== 'production') return;

  const ga4Payload = {
    title: label,
    location: itemLocation,
    list_position: listPosition,
    mode,
    referrer: globalThis?.location?.pathname
  };
  customEventGa4(
    gtmTriggers?.shopBrokenImage,
    ga4Events?.view_broken_image,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.view_broken_image, ga4Payload);
};

const cleanProduct = ({
  id,
  name,
  price,
  quantity,
  brand,
  listId,
  listName,
  position
} = {}) => ({
  item_id: id,
  item_name: name,
  ...(!!price && { price }),
  ...(!!quantity && { quantity }),
  ...(!!brand && { item_brand: brand }),
  ...(!!listId && { item_list_id: listId }),
  ...(!!listName && { item_list_name: listName }),
  ...(!!position && { index: position })
});

const trackListCtr = ({
  eventName = 'unknown_trackListCtr',
  mode = 'unknown_trackListCtr',
  listId,
  listName,
  listPosition,
  itemId,
  itemPath,
  filter,
  sort
} = {}) => {
  const ga4Payload = {
    mode,
    id: listId,
    title: listName,
    location: pagePathQueryFragment(),
    list_position: listPosition,
    item_id: itemId,
    url: itemPath,
    filter,
    sort
  };
  customEventGa4(gtmTriggers?.shopListCtr, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

const trackListView = ({
  listId = undefined,
  listName = undefined,
  cleanPdtList = []
} = {}) => {
  const eventName = ga4Events?.view_item_list;
  const ga4Payload = {
    item_list_id: listId,
    item_list_name: listName,
    items: cleanPdtList.map((props) => ({
      ...props,
      item_list_id: listId,
      item_list_name: listName
    }))
  };
  customEventGa4(gtmTriggers?.shopListAction, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

const trackListItemSelect = ({
  isSearch = false,
  listData = {},
  pdtData = {},
  extraData = {}
} = {}) => {
  const eventName = ga4Events?.select_item;
  const cleanPdtData = cleanProduct(pdtData);
  const ga4Payload = {
    item_list_id: listData?.listId,
    item_list_name: listData?.listName,
    items: [
      {
        ...cleanPdtData,
        item_list_id: listData?.listId,
        item_list_name: listData?.listName
      }
    ]
  };

  customEventGa4(gtmTriggers?.shopListAction, eventName, ga4Payload);
  amplitudeTrack(eventName, {
    list_type: isSearch ? 'search' : 'collection',
    list_mode: listData?.mode,
    list_id: listData?.listId,
    list_name: listData?.listName,
    list_first_path: extraData?.pathFragments?.first,
    list_final_path: extraData?.pathFragments?.last,
    list_filter: listData?.filter,
    list_sort: listData?.sort,
    list_position: listData?.listPosition,
    item_id: extraData?.itemId,
    item_price: pdtData?.price,
    pdt_id: pdtData?.id,
    pdt_name: pdtData?.name,
    pdt_path: extraData?.path,
    pdt_brand: pdtData?.brand,
    pdt_badge: extraData?.pdtFlag,
    pdt_percent_off: extraData?.percentOffValue,
    pdt_shipping_text: extraData?.shippingText,
    pdt_showroom_text: extraData?.someColorsInShowroom,
    pdt_rating: extraData?.averageRating,
    pdt_total_sold: extraData?.totalSold,
    pdt_colours: extraData?.colourOptions
  });
};

const trackWishlistAction = ({
  eventName = 'unknown_trackWishlistAction',
  value = 0,
  cleanPdtList = [],
  location = undefined,
  trigger = undefined,
  referrer = undefined
} = {}) => {
  const ga4Payload = {
    currency: globalThis?.HV?.CURRENCY_CODE,
    items: cleanPdtList,
    value,
    location,
    trigger,
    referrer
  };
  customEventGa4(gtmTriggers?.shopIntentAction, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);

  if (eventName === ga4Events?.add_to_wishlist) {
    const eventID = `${uuidv4()}-${new Date().getTime()}`;
    ttqEventGa4({
      ttqName: ttqEvents?.sevtAddToWishlist,
      ttqEventID: eventID,
      ttqData: {
        content_type: 'product',
        contents: cleanPdtList.map((props) => ({
          content_id: props.item_id,
          content_name: props.item_name,
          quantity: 1,
          price: value
        })),
        value,
        currency: globalThis?.HV?.CURRENCY_CODE
      }
    });
    ahoyTrack({
      eventType: globalThis.HV.HIT_TYPES.ecommerce,
      eventData: {
        event: ahoyEvents?.add_to_wishlist,
        items: cleanPdtList,
        location,
        url: referrer,
        event_id: eventID
      }
    });
  }
};

// --------------------
// Filter Tracking
// --------------------

const trackFilterOpen = ({ pagePath, filter } = {}) => {
  const ga4Payload = {
    filter,
    referrer: pagePath
  };
  customEventGa4(
    gtmTriggers?.shopFilter,
    ga4Events?.shop_filter_menu_open,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.shop_filter_menu_open, ga4Payload);
};

const trackFilterActive = ({ pagePath, filter, params } = {}) => {
  const ga4Payload = {
    filter: `${filter}${!isEmpty(params) ? ` :: ${params}` : ''}`,
    referrer: pagePath
  };
  customEventGa4(
    gtmTriggers?.shopFilter,
    ga4Events?.shop_filter_menu_active,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.shop_filter_menu_active, ga4Payload);
};

const trackEcmSortApply = ({ sort, location, url } = {}) => {
  const ga4Payload = {
    sort,
    location,
    url
  };
  customEventGa4(
    gtmTriggers?.shopFilterApply,
    ga4Events?.shop_filter_sort_option,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.shop_filter_sort_option, ga4Payload);
};

const trackEcmFilterApply = ({
  filterString,
  sortString,
  results,
  location,
  url,
  mode
} = {}) => {
  const ga4Payload = {
    filter: filterString,
    sort: sortString,
    value: results,
    location,
    url,
    mode
  };
  customEventGa4(
    gtmTriggers?.shopFilterApply,
    ga4Events?.shop_filter_success,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.shop_filter_success, ga4Payload);
};

const trackListActionAfterFilterSort = (
  eventName = 'unknown_trackListActionAfterFilterSort',
  {
    location,
    sortOption,
    variantId,
    position,
    mode = 'unknown_trackListActionAfterFilterSort'
  } = {}
) => {
  const ga4Payload = {
    item_id: variantId,
    location,
    sort: sortOption,
    list_position: position,
    mode
  };
  customEventGa4(gtmTriggers?.shopFilterIntent, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

const trackSaleWidgetAction = (
  eventName = 'unknown_trackSaleWidgetAction',
  {
    listName = 'unknown_trackSaleWidgetAction',
    listPosition,
    itemId,
    image,
    title,
    url,
    target
  } = {}
) => {
  const ga4Payload = {
    referrer: pagePathQueryFragment(),
    location: listName,
    list_position: listPosition,
    item_id: itemId,
    trigger: image,
    title,
    url,
    mode: target
  };
  customEventGa4(gtmTriggers?.shopWidget, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

// --------------------
// Virtual Pageviews
// --------------------

const pvPageSchema = {
  title: null,
  path: null,
  handle: null,
  cg1: null,
  cg2: null,
  cg3: null,
  cg4: null
};

const getPvStore = (key) =>
  JSON.parse(window.sessionStorage.getItem(key) ?? '{}');

const setPvStore = (key, data) =>
  window.sessionStorage.setItem(key, JSON.stringify(data));

const updatePvDataObjects = (previousPage, currentPage) => {
  const payload = {
    event: 'pgView',
    previousPage,
    currentPage
  };
  if (window?.dataLayer) {
    window.dataLayer.push(payload);
  }
  setPvStore(RailsVars.PAGE_VIEW_KEY, payload);
};

const trackVirtualPageview = (currPage) => {
  const prevPage = getPvStore(RailsVars.PAGE_VIEW_KEY).currentPage;
  if (isEmpty(getPvStore(RailsVars.LAST_REAL_PAGE_VIEW_KEY))) {
    setPvStore(RailsVars.LAST_REAL_PAGE_VIEW_KEY, prevPage);
  }
  updatePvDataObjects(prevPage, merge({}, pvPageSchema, currPage));
};

const trackPreviousPageview = (type) => {
  const { currentPage: toBePrevious, previousPage: storedPrev } = getPvStore(
    RailsVars.PAGE_VIEW_KEY
  );
  const toBeCurrent =
    type === 'real'
      ? getPvStore(RailsVars.LAST_REAL_PAGE_VIEW_KEY)
      : storedPrev;
  updatePvDataObjects(toBePrevious, toBeCurrent);
  setPvStore(RailsVars.LAST_REAL_PAGE_VIEW_KEY, {});
};

export {
  RESPONSE_PROVIDERS,
  ahoyTrackPageView,
  trackShare,
  trackBrokenImage,
  cleanProduct,
  trackListCtr,
  trackListView,
  trackListItemSelect,
  trackWishlistAction,
  trackFilterOpen,
  trackFilterActive,
  trackEcmSortApply,
  trackEcmFilterApply,
  trackListActionAfterFilterSort,
  trackSaleWidgetAction,
  trackVirtualPageview,
  trackPreviousPageview
};
