import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';

import atoms from '~/containers/Header/states/atoms';
import View from '~/containers/Header/view';
import airbrake from '~/libs/airbrake';
import useGetProfileInfo from '~/hooks/shared/useGetProfileInfo';
import useWindowWidth from '~/hooks/shared/useWindowWidth';
import useRecalcOnFontsLoad from '~/hooks/shared/useRecalcOnFontsLoad';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { SUCCESS_CODE, sendGet } from '~/utils/requestAPI';

export default function Container({ modifierClasses = '' }) {
  const [isLoading, setLoading] = useState(true);
  const setNavigation = useSetRecoilState(atoms.navigation);

  useWindowWidth();
  useRecalcOnFontsLoad();
  useGetProfileInfo({ isPrivate: true });

  useEffect(() => {
    const cancelSource = axios.CancelToken;
    const source = cancelSource.source();

    async function initRequest() {
      try {
        const res = await sendGet('/navigation');
        const { status, data } = res || {};
        if (status === SUCCESS_CODE) {
          setNavigation(defaultForUndefinedOrNull(data, []));
        } else if (status > 0) {
          airbrake.notify({
            error: 'Navigation not found',
            params: { info: res }
          });
        }
      } catch (error) {
        airbrake.notify({ error });
      } finally {
        setLoading(false);
      }
    }

    initRequest();

    return () => {
      source.cancel('cancel initRequest effect');
    };
  }, [setNavigation]);

  return <View modifierClasses={modifierClasses} isLoading={isLoading} />;
}
