import { isEmpty } from 'lodash';

import RailsVars from '~/apps/railsVariables.js.erb';
import { amplitudeTrack } from '~/utils/analytics/amplitude';

const CHANGE_EVENT = 'sessionStorage-changed';

const getPageViewData = (key) =>
  JSON.parse(window?.sessionStorage?.getItem(key) ?? '{}');

const setPageViewData = (key, data) => {
  window?.sessionStorage?.setItem(key, JSON.stringify(data));
  document.dispatchEvent(new CustomEvent(CHANGE_EVENT));
};

const setPageView = (previousPage, currentPage) => {
  const pageViewData = {
    event: 'pgView',
    previousPage,
    currentPage
  };
  if (window.dataLayer) {
    window.dataLayer.push(pageViewData);
  }
  amplitudeTrack('page_view', pageViewData);
  return setPageViewData(RailsVars.PAGE_VIEW_KEY, pageViewData);
};

const sendPrevPageView = (type) => {
  const pageInfo = getPageViewData(RailsVars.PAGE_VIEW_KEY);
  const previousPage = pageInfo.currentPage;
  const currentPage =
    type === 'real'
      ? getPageViewData(RailsVars.LAST_REAL_PAGE_VIEW_KEY)
      : pageInfo.previousPage;
  setPageView(previousPage, currentPage);
  return setPageViewData(RailsVars.LAST_REAL_PAGE_VIEW_KEY, {});
};

const sendPageViewGTM = (pageInfo) => {
  const previousPage =
    getPageViewData(RailsVars.PAGE_VIEW_KEY).currentPage ?? {};
  const currentPage = {
    title: pageInfo.title,
    path: pageInfo.path,
    handle: pageInfo.handle,
    cg1: pageInfo.cg1,
    cg2: null,
    cg3: null,
    cg4: null
  };
  if (isEmpty(getPageViewData(RailsVars.LAST_REAL_PAGE_VIEW_KEY))) {
    setPageViewData(RailsVars.LAST_REAL_PAGE_VIEW_KEY, previousPage);
  }
  return setPageView(previousPage, currentPage);
};

export { sendPrevPageView, sendPageViewGTM };
