import React, { useCallback, useState, useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { USER_PATHS } from '~/containers/Account/constants';
import { AUTH_MODE, AUTH_CONTENT } from '~/containers/ModalsManager/constants';
import atoms from '~/containers/shared/states/atoms';
import headerAtoms from '~/containers/Header/states/atoms';
import modalAtoms from '~/containers/ModalsManager/states/atoms';
import Avatar from '~/components/Header/Avatar';
import AccountDropdown from '~/components/Header/ControlRow/AccountDropdown';
import { hitLocations } from '~/utils/analytics/tracking';
import './AccountIcon.scss';

export default function AccountIcon({ expandable = false }) {
  const [acctHover, setAcctHover] = useState(false);
  const loggedUser = useRecoilValue(atoms.currentCustomer);
  const setDropdownOpen = useSetRecoilState(headerAtoms.dropdownOpen);
  const authConfigUpdate = useSetRecoilState(modalAtoms.authConfigUpdate);

  const toggleHover = (toggle) => () => setAcctHover(toggle);

  const handleNotLogin = useCallback(
    () =>
      authConfigUpdate({
        authMode: AUTH_MODE?.LOGIN,
        authContent: AUTH_CONTENT?.GENERAL,
        location: hitLocations?.top_nav_account
      }),
    [authConfigUpdate]
  );

  useEffect(() => {
    setDropdownOpen(acctHover);
  }, [acctHover, setDropdownOpen]);

  if (isEmpty(loggedUser))
    return (
      <div
        className="c-nvAcctBtn u-inline-block u-p-pointer u-t-nooutline"
        role="button"
        tabIndex={-1}
        onClick={handleNotLogin}
      >
        <Avatar />
      </div>
    );

  if (!expandable)
    return (
      <a
        className="c-nvAcctBtn is-link u-inline-block u-t-body u-t-nolined u-t-nooutline is-still"
        href={USER_PATHS?.home?.path}
      >
        <Avatar user={loggedUser} />
      </a>
    );

  return (
    <div
      role="button"
      tabIndex={-1}
      className={classNames('c-nvAcctBtn is-menu u-inline-block u-p-pointer', {
        'is-expanded': acctHover
      })}
      onMouseEnter={toggleHover(true)}
      onMouseLeave={toggleHover(false)}
    >
      <div className="c-nvAcctBtn__icon u-animate-all">
        <Avatar user={loggedUser} />
      </div>
      <div className="c-nvAcctBtn__menu u-animate-all">
        <AccountDropdown />
      </div>
    </div>
  );
}
