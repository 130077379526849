import { atom } from 'recoil';

import { atomKeys } from '~/containers/shared/constants';

const isClient = atom({
  key: atomKeys.IS_CLIENT,
  default: typeof window === 'object'
});

const viewportWidth = atom({
  key: atomKeys.VIEWPORT_WIDTH,
  default: undefined
});

const showHeader = atom({
  key: atomKeys.SHOW_HEADER,
  default: true
});

const headerHeight = atom({
  key: atomKeys.HEADER_HEIGHT,
  default: 0
});

const cartCount = atom({
  key: atomKeys.CART_COUNT,
  default: 0
});

const currentCustomer = atom({
  key: atomKeys.CURRENT_CUSTOMER,
  default: {}
});

const publicProfile = atom({
  key: atomKeys.PUBLIC_PROFILE,
  default: {}
});

const isCollectionPage = atom({
  key: atomKeys.IS_COLLECTION_PAGE,
  default: false
});

const isFetchingCollection = atom({
  key: atomKeys.IS_FETCHING_COLLECTION,
  default: false
});

const klevuResponse = atom({
  key: atomKeys.KLEVU_RESPONSE,
  default: false
});

const openFilterPills = atom({
  key: atomKeys.OPEN_FILTER_PILLS,
  default: {
    isApply: null,
    list: null
  }
});

const closeFilterMethod = atom({
  key: atomKeys.CLOSE_FILTER_METHOD,
  default: undefined
});

const listRefinement = atom({
  key: atomKeys.LIST_REFINEMENT,
  default: {
    filterString: null,
    sortString: null,
    count: -1,
    results: undefined
  }
});

const viewedVariants = atom({
  key: atomKeys.VIEW_ITEMS_GRID,
  default: {
    listName: null,
    listId: null,
    receivedData: [],
    trackedData: []
  }
});

const isFetchingProductDetail = atom({
  key: atomKeys.IS_FETCHING_PRODUCT_DETAIL,
  default: null
});

const isProductPage = atom({
  key: atomKeys.IS_PRODUCT_PAGE,
  default: false
});

const isProductEnd = atom({
  key: atomKeys.IS_PRODUCT_END,
  default: false
});

const activatedControl = atom({
  key: atomKeys.ACTIVATED_CONTROL,
  default: null
});

const shoppingSummary = atom({
  key: atomKeys.SHOPPING_SUMMARY,
  default: {}
});

const isFetchingSEO = atom({
  key: atomKeys.IS_FETCHING_SEO,
  default: true
});

export default {
  isClient,
  viewportWidth,
  showHeader,
  headerHeight,
  cartCount,
  currentCustomer,
  publicProfile,
  isCollectionPage,
  isFetchingCollection,
  klevuResponse,
  activatedControl,
  openFilterPills,
  closeFilterMethod,
  listRefinement,
  viewedVariants,
  isFetchingProductDetail,
  isProductPage,
  isProductEnd,
  shoppingSummary,
  isFetchingSEO
};
