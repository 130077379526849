import axios from 'axios';

import { createCDNApiInstance } from './axiosInstance';
import commonRequests from './commonRequests';

export const errorHandler = (error) => {
  if (!axios.isCancel(error)) {
    let res;
    const buildKey = (name) => `REQUEST_AXIOS_ERROR_${name}`;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errObj = { error: error.response };
      const { status } = error.response;
      res = {
        key: buildKey(status),
        status,
        ...errObj
      };
      window[res] = errObj;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const errObj = { error: error.request };
      const { status } = error.request;
      res = {
        key: buildKey(status),
        status,
        ...errObj
      };
      window[res] = errObj;
    } else {
      // Something happened in setting up the request that triggered an Error
      res = {
        key: `REQUEST_AXIOS_ERROR: ${error.message}`,
        status: -1,
        ...error
      };
      window.REQUEST_AXIOS_ERROR = { error };
    }
    return res;
  }
  return {
    message: 'Opps! Something went wrong while setting up request',
    status: -1
  };
};

export const cancelSource = axios.CancelToken.source();

export const SUCCESS_CODE = 200;

const instanceV1 = createCDNApiInstance();
const instanceV2 = createCDNApiInstance('v2');

export const sendGet = (path, queries = {}, options = {}) =>
  commonRequests.sendGet(instanceV1, path, queries, options);

export const sendPost = (path, body, options = {}) =>
  commonRequests.sendPost(instanceV1, path, body, options);

export const sendPatch = (path, body, options = {}) =>
  commonRequests.sendPatch(instanceV1, path, body, options);

export const sendDelete = (path, options = {}) =>
  commonRequests.sendDelete(instanceV1, path, options);

export const sendGetV2 = (path, queries = {}, options = {}) =>
  commonRequests.sendGet(instanceV2, path, queries, options);
