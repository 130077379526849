import React from 'react';

import './NavLogo.scss';

export default function NavLogo() {
  return (
    <a
      className="c-nvLogo u-inline-block u-t-primary u-t-nolined is-still"
      href="/"
    >
      <span
        className="c-nvLogo__mark ic-bef ic-logo-combi is-glyph"
        aria-hidden
      />
    </a>
  );
}
