import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import useFontFaceObserver from 'use-font-face-observer';
import { isEqual } from 'lodash';

import atoms from '~/containers/shared/states/atoms';
import headerAtoms from '~/containers/Header/states/atoms';

export default function useRecalcOnFontsLoad(
  fonts = { family: 'Sofia' },
  windowEvent = 'resize',
  eventDelay = 150
) {
  const isClient = useRecoilValue(atoms.isClient);
  const [recalc, setRecalc] = useRecoilState(headerAtoms.recalcOnFontsLoad);
  const [localRecalc, setLocalRecalc] = useState(false);

  const isFontListLoaded = useFontFaceObserver([fonts]);

  useEffect(() => {
    setLocalRecalc((prev) => (isEqual(recalc, prev) ? prev : recalc));
  }, [recalc]);

  useEffect(() => {
    let lazyEvent = null;

    if (isClient && (isFontListLoaded || localRecalc)) {
      lazyEvent = setTimeout(() => {
        window.dispatchEvent(new Event(windowEvent));
        setRecalc(false);
        setLocalRecalc(false);
      }, eventDelay);
    }

    return () => {
      clearTimeout(lazyEvent);
    };
  }, [
    eventDelay,
    isClient,
    isFontListLoaded,
    localRecalc,
    setRecalc,
    windowEvent
  ]);
}
