import { pickBy, identity } from 'lodash';
import keyMirror from 'keymirror';

const atomKeys = keyMirror({
  IS_CLIENT: null,
  VIEWPORT_WIDTH: null,
  SHOW_HEADER: null,
  HEADER_HEIGHT: null,
  CART_COUNT: null,
  CURRENT_CUSTOMER: null,
  PUBLIC_PROFILE: null,
  IS_COLLECTION_PAGE: null,
  IS_FETCHING_COLLECTION: null,
  KLEVU_RESPONSE: null,
  ACTIVATED_CONTROL: null,
  OPEN_FILTER_PILLS: null,
  CLOSE_FILTER_METHOD: null,
  LIST_REFINEMENT: null,
  VIEW_ITEMS_GRID: null,
  IS_FETCHING_PRODUCT_DETAIL: null,
  IS_PRODUCT_PAGE: null,
  IS_PRODUCT_END: null,
  SHOPPING_SUMMARY: null,
  IS_FETCHING_SEO: null
});

const selectorKeys = keyMirror({
  UPDATE_CURRENT_CUSTOMER: null,
  UPDATE_PRIVATE_PROFILE: null,
  UPDATE_PUBLIC_PROFILE: null,
  UPDATE_REFERRAL_CONFIG: null
});

const breakPoints = {
  xs: 321,
  sm: 768,
  md: 992,
  lg: 1201,
  xl: 1345,
  mx: 1501
};

const mediaQuery = ({ min, max }) =>
  pickBy(
    {
      minWidth: breakPoints[min],
      maxWidth: breakPoints[max] - 1
    },
    identity
  );

const mediaQueryPreset = {
  smallMobile: mediaQuery({ max: 'xs' }),
  mobile: mediaQuery({ max: 'sm' }),
  tablet: mediaQuery({ min: 'sm', max: 'md' }),
  smallDesktop: mediaQuery({ min: 'md', max: 'lg' }),
  desktop: mediaQuery({ min: 'md' }),
  largeDesktop: mediaQuery({ min: 'lg' }),
  max: mediaQuery({ max: 'mx' })
};

const PRIMARY_COLOR = '#00bdb3';

const TAXONS_PAGE_NAME = 'collection';
const PRODUCTS_PAGE_NAME = 'product';
const SEARCH_RESULTS_PAGE_NAME = 'search';

const empty = null;

const DEFAULT_STORE_KEY = 'default_store_key';

const FILTER_CONTROL = 'Filter';
const SORT_CONTROL = 'Sort';

const FILTER_CATES = 'Categories';
const FILTER_PRICE = 'Price';
const FILTER_SHIPPING = 'Shipping';
const FILTER_FIRMNESS = 'Firmness';
const FILTER_COLOR = 'Colour';
const FILTER_WIDTH = 'Width';

const SIZE_FILTER_UNIT = 'cm';
const SIZE_FILTER_SECONDARY_UNIT = 'm';

const OVERLAY_FILTER_MOBILE = 'filter-mobile';
const OVERLAY_SORT_MOBILE = 'sort-mobile';

const ADD_TO_WISHLIST_API = '/wishlist/add_item.json';
const REMOVE_FROM_WISHLIST_API = '/wishlist/remove_item.json';
const ADD_TO_WISHLIST_AFTER_LOGGED_STORE_KEY =
  'ADD_TO_WISHLIST_AFTER_LOGGED_STORE_KEY';
const PRODUCT_GRID_ADD_TO_WISHLIST_AFTER_LOGGED_STORE_KEY =
  'PRODUCT_GRID_ADD_TO_WISHLIST_AFTER_LOGGED_STORE_KEY';

const AUTHENTICATE_API = '/sessions/authenticate';

const PRODUCT_RELATED_POSTS_API = '/ideas/related_posts';
const PRODUCT_QNA_API = '/product_qna';

const GEOLOCATION_DB_API_KEY = '0f761a30-fe14-11e9-b59f-e53803842572';
const SHOWROOM_NAME = 'HipVan Atelier Pop-up';

const MALAYSIA_COUNTRY_CODE = 'MY';
const MALAYSIA_MODAL_SHOWED = 'MALAYSIA_MODAL_SHOWED';
const CURRENT_USER_KEY = 'CURRENT_USER_KEY';

const SEO_API = '/search/seo_info';
const PRODUCT_REVIEWS_API = '/search/product_reviews.json';

const VIDEO_PLATFORM_KEY = keyMirror({
  youtube: null,
  vimeo: null
});

const VIDEO_PLATFORM_PRESETS = {
  [VIDEO_PLATFORM_KEY.youtube]: {
    playerVars: {
      rel: 0,
      HD: 1,
      enablejsapi: 1,
      modestbranding: 1,
      autoplay: 1
    }
  },
  [VIDEO_PLATFORM_KEY.vimeo]: {
    playerOptions: {
      title: false,
      byline: false,
      autoplay: true
    }
  }
};

const KEY_CODE_ENTER = 13;
const KEY_CODE_LOWER_E = 69;
const KEY_CODE_PERIOD = 190;

const NOTIFY_ME_OUT_OF_STOCK_API = '/out_of_stock_notification/add_item.json';
const NOTIFY_ME_OUT_OF_STOCK_STORE_KEY = 'NOTIFY_ME_OUT_OF_STOCK_STORE_KEY';

const NL_SUBSCRIBE_URL = '/subscribe_to_list';
const NL_SUBSCRIBE_DEFAULT_LIST_ID = '1622847';

const HIPVAN_DOMAIN = 'hipvan.com';

const ADD_TO_CART_API = '/cart_sessions/add_items.json';

const INIT_QTY_IN_STOCK = 1;

const MOBILE_APP_PRESERVE_PARAMS = ['platform', 'mobile_platform'];

const previewProductKeys = keyMirror({
  PREVIEW_PRODUCT: null,
  PRODUCT_POSITION: null,
  PRODUCT_VARIANT_ID: null,
  PRODUCT_VARIANTS: null,
  MODAL_VISIBLE_TIMESTAMPS: null,
  MODAL_VISIBLE_STATE: null,
  IS_LOADING: null,
  IS_FAILURE: null,
  ENGAGED_PRODUCT_DATA: null,
  UPDATE_SHOW_MODAL: null
});

export {
  atomKeys,
  selectorKeys,
  mediaQuery,
  mediaQueryPreset,
  empty,
  PRIMARY_COLOR,
  TAXONS_PAGE_NAME,
  PRODUCTS_PAGE_NAME,
  SEARCH_RESULTS_PAGE_NAME,
  DEFAULT_STORE_KEY,
  AUTHENTICATE_API,
  PRODUCT_RELATED_POSTS_API,
  PRODUCT_QNA_API,
  ADD_TO_WISHLIST_AFTER_LOGGED_STORE_KEY,
  ADD_TO_WISHLIST_API,
  REMOVE_FROM_WISHLIST_API,
  FILTER_CONTROL,
  SORT_CONTROL,
  FILTER_CATES,
  FILTER_PRICE,
  FILTER_SHIPPING,
  FILTER_FIRMNESS,
  FILTER_COLOR,
  FILTER_WIDTH,
  SIZE_FILTER_UNIT,
  SIZE_FILTER_SECONDARY_UNIT,
  OVERLAY_FILTER_MOBILE,
  OVERLAY_SORT_MOBILE,
  GEOLOCATION_DB_API_KEY,
  SHOWROOM_NAME,
  MALAYSIA_COUNTRY_CODE,
  MALAYSIA_MODAL_SHOWED,
  CURRENT_USER_KEY,
  SEO_API,
  PRODUCT_REVIEWS_API,
  VIDEO_PLATFORM_PRESETS,
  VIDEO_PLATFORM_KEY,
  KEY_CODE_ENTER,
  KEY_CODE_LOWER_E,
  KEY_CODE_PERIOD,
  NOTIFY_ME_OUT_OF_STOCK_API,
  NOTIFY_ME_OUT_OF_STOCK_STORE_KEY,
  PRODUCT_GRID_ADD_TO_WISHLIST_AFTER_LOGGED_STORE_KEY,
  NL_SUBSCRIBE_URL,
  NL_SUBSCRIBE_DEFAULT_LIST_ID,
  HIPVAN_DOMAIN,
  INIT_QTY_IN_STOCK,
  MOBILE_APP_PRESERVE_PARAMS,
  ADD_TO_CART_API,
  previewProductKeys
};
