import { v4 as uuidv4 } from 'uuid';

import { SEARCH_PAGE_TITLE } from '~/containers/SearchResult/constants';
import {
  gtmTriggers,
  ga4Events,
  customEventGa4,
  cachedPageViewData,
  fbqEvents,
  fbqEventGa4,
  ttqEvents,
  ttqEventGa4,
  ptqEvents,
  ptqEventGa4
} from '~/utils/analytics/gtm';
import { ahoyEvents, ahoyTrack } from '~/utils/analytics/ahoy';
import { amplitudeTrack } from '~/utils/analytics/amplitude';
import { defaultForUndefinedOrNull } from '~/utils/helper';

const RTB_TOP_IDS_COUNT = 5;

const trackSearch = ({ queryId, query, total = 0, variants = [] } = {}) => {
  const cleanList = variants.map(({ id, name, finalPrice } = {}) => ({
    id,
    name,
    price: finalPrice
  }));

  customEventGa4(gtmTriggers?.metaSearch, 0, {
    search: {
      id: queryId,
      name: SEARCH_PAGE_TITLE,
      query,
      topIds: cleanList.slice(0, RTB_TOP_IDS_COUNT).map(({ id }) => id),
      total,
      variants: cleanList
    }
  });
  // Match GTM 'GA4 ECM - search'
  amplitudeTrack(ga4Events?.search, {
    title: query,
    trigger: defaultForUndefinedOrNull(query, '').toLowerCase(),
    value: total
  });

  const eventID = `${uuidv4()}-${new Date().getTime()}`;
  fbqEventGa4({
    fbqType: fbqEvents?.trackStandard,
    fbqName: fbqEvents?.sevtSearch,
    fbqData: { search_string: MediaQueryList },
    fbqEventID: eventID
  });
  ttqEventGa4({
    ttqName: ttqEvents?.sevtSearch,
    ttqData: { query },
    ttqEventID: eventID
  });
  ptqEventGa4({
    ptqType: ptqEvents?.trackStandard,
    ptqName: ptqEvents?.sevtSearch,
    ptqData: { search_string: query },
    ptqEventID: eventID
  });
  ahoyTrack({
    eventType: globalThis.HV.HIT_TYPES.ecommerce,
    eventData: {
      event: ahoyEvents?.search,
      event_id: eventID,
      search_string: query
    }
  });
};

const trackSearchSuggestAction = (
  eventName = 'unknown_trackSearchSuggestAction',
  {
    query,
    total = 0,
    title,
    url,
    listPosition,
    mode = 'unknown_trackSearchSuggestAction',
    trigger = 'unknown_trackSearchSuggestAction'
  } = {}
) => {
  const ga4Payload = {
    title,
    url,
    list_position: listPosition,
    referrer: query,
    value: total,
    mode,
    trigger,
    location: cachedPageViewData()?.currentPage?.path,
    cg1: cachedPageViewData()?.currentPage?.cg1
  };
  customEventGa4(gtmTriggers?.shopSearchSuggest, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

export { trackSearch, trackSearchSuggestAction };
