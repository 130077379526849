/* eslint-disable react/jsx-props-no-spreading,jsx-a11y/label-has-associated-control */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useCookies } from 'react-cookie';
import { isEmpty } from 'lodash';

import {
  AUTH_MODE,
  AUTH_CONTENT,
  REQUIRED_ERROR_MSG,
  REQUEST_INFO_LATER_KEY,
  REQUEST_INFO_LATER_DURA
} from '~/containers/ModalsManager/constants';
import {
  UPDATE_PROFILE_API,
  CUSTOMER_INFO_FIELD_KEYS,
  GENDER_OPTIONS
} from '~/containers/Account/constants';
import atoms from '~/containers/shared/states/atoms';
import modalAtoms from '~/containers/ModalsManager/states/atoms';
import HvModal from '~/components/shared/HvModal';
import useYearMonthDay from '~/hooks/shared/useYearMonthDay';
import { sendPost } from '~/utils/requestAPI';
import { getDateObj, getFormattedBirthDay } from '~/utils/helper';
import { ga4Events } from '~/utils/analytics/gtm';
import {
  MODAL_ACCOUNT_INFO,
  trackOnboardModalAction
} from '~/containers/ModalsManager/analytics';
import airbrake from '~/libs/airbrake';

export default function AccountInfoModal({ pathname = 'unknown' }) {
  const [loggedCustomer, setLoggedCustomer] = useRecoilState(
    atoms.currentCustomer
  );
  const { authMode, authContent } = useRecoilValue(modalAtoms.authConfig);
  const [showModal, setShowModal] = useRecoilState(
    modalAtoms.showAccountInfoModal
  );
  const setReadyForCallouts = useSetRecoilState(modalAtoms.readyForCallouts);
  const setShowProfileModal = useSetRecoilState(
    modalAtoms.showProfileInfoModal
  );

  const modalHeader = useMemo(() => {
    if (authMode === AUTH_MODE?.SIGNUP && authContent === AUTH_CONTENT?.GENERAL)
      return "Let's Get Started!";
    return 'Update Your Info!';
  }, [authContent, authMode]);

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    birthday = '',
    gender = '',
    auth_user_id: userId = ''
  } = loggedCustomer;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const bDay = useMemo(() => getDateObj(birthday), [birthday]);

  const { yearOptions, monthOptions, dayOptions } = useYearMonthDay({
    month: bDay.month,
    year: bDay.year
  });

  const formattedBirthDay = useCallback(
    (dateObj) => getFormattedBirthDay(dateObj),
    []
  );

  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  const pausedRequestCookie = useMemo(
    () => (isEmpty(userId) ? '' : `${REQUEST_INFO_LATER_KEY}/${userId}`),
    [userId]
  );

  const [, setCookie] = useCookies([pausedRequestCookie]);

  const handleRemindLater = useCallback(() => {
    setShowModal(false);
    setCookie(pausedRequestCookie, 1, {
      expires: DateTime.now().plus(REQUEST_INFO_LATER_DURA).toJSDate()
    });
    trackOnboardModalAction(
      ga4Events?.click_info_request_modal_close,
      MODAL_ACCOUNT_INFO,
      pathname
    );
    setReadyForCallouts(true);
  }, [
    pausedRequestCookie,
    pathname,
    setCookie,
    setReadyForCallouts,
    setShowModal
  ]);

  const updateInfo = useCallback(
    async (form) => {
      try {
        const res = await sendPost(UPDATE_PROFILE_API, {
          customer: { ...form }
        });
        const { success = false } = res.data;
        if (success) {
          setLoggedCustomer(res?.data?.data?.customer);
          onClose();
          setShowProfileModal(true);
        } else {
          onClose();
        }
      } catch (error) {
        airbrake.notify({ error });
      }
    },
    [onClose, setLoggedCustomer, setShowProfileModal]
  );

  const onSubmit = useCallback(
    (formData) => {
      trackOnboardModalAction(
        ga4Events?.click_info_request_modal_success,
        MODAL_ACCOUNT_INFO,
        pathname
      );
      updateInfo({
        ...formData,
        birthday: formattedBirthDay(formData.bDay),
        requested_update_info: true
      });
    },
    [formattedBirthDay, pathname, updateInfo]
  );

  useEffect(() => {
    if (showModal) {
      setReadyForCallouts(false);
      trackOnboardModalAction(
        ga4Events?.view_info_request_modal,
        MODAL_ACCOUNT_INFO,
        pathname
      );
    }
  }, [pathname, setReadyForCallouts, showModal]);

  return (
    <HvModal
      onClose={onClose}
      isOpen={showModal}
      blockName="c-infoReq"
      contentLabel="Request Update Info"
      otherConfig={{
        shouldCloseOnOverlayClick: false,
        shouldCloseOnEsc: false
      }}
      defaultHeader={false}
    >
      <div className="c-infoReq__section is-form">
        <div className="c-infoReq__cap">
          <h3 className="c-infoReq__cap-h u-t-bold">{modalHeader}</h3>
          <p className="c-infoReq__cap-p u-t-grey">
            This information will not be visible to anyone.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="c-infoReq__field">
            <label
              htmlFor={CUSTOMER_INFO_FIELD_KEYS.FIRST_NAME.id}
              className="c-infoReq__label u-block u-required"
            >
              First Name
            </label>
            <input
              type="text"
              className="c-infoReq__input o-input o-fd o-fd-fade u-block"
              id={CUSTOMER_INFO_FIELD_KEYS.FIRST_NAME.id}
              defaultValue={firstName}
              placeholder="Enter your first name"
              {...register(CUSTOMER_INFO_FIELD_KEYS.FIRST_NAME.fieldName, {
                required: true
              })}
            />
            {errors[CUSTOMER_INFO_FIELD_KEYS.FIRST_NAME.fieldName] && (
              <label
                htmlFor={CUSTOMER_INFO_FIELD_KEYS.FIRST_NAME.id}
                className="error"
              >
                {REQUIRED_ERROR_MSG}
              </label>
            )}
          </div>
          <div className="c-infoReq__field">
            <label
              htmlFor={CUSTOMER_INFO_FIELD_KEYS.LAST_NAME.id}
              className="c-infoReq__label u-block u-required"
            >
              Last Name
            </label>
            <input
              type="text"
              className="c-infoReq__input o-input o-fd o-fd-fade u-block"
              id={CUSTOMER_INFO_FIELD_KEYS.LAST_NAME.id}
              defaultValue={lastName}
              placeholder="Enter your last name"
              {...register(CUSTOMER_INFO_FIELD_KEYS.LAST_NAME.fieldName, {
                required: true
              })}
            />
            {errors[CUSTOMER_INFO_FIELD_KEYS.LAST_NAME.fieldName] && (
              <label
                htmlFor={CUSTOMER_INFO_FIELD_KEYS.LAST_NAME.id}
                className="error"
              >
                {REQUIRED_ERROR_MSG}
              </label>
            )}
          </div>
          <div className="c-infoReq__field is-bday">
            <label
              htmlFor={CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_DAY.id}
              className="c-infoReq__label u-block u-required"
            >
              Birthday
            </label>
            <div className="c-infoReq__row">
              <div className="c-infoReq__seg is-bday">
                <select
                  className="c-infoReq__input c-infoReq__select-menu o-input o-fd o-fd-fade u-b-white u-t-nooutline"
                  id={CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_DAY.id}
                  defaultValue={bDay.day}
                  {...register(
                    CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_DAY.fieldName,
                    {
                      required: true
                    }
                  )}
                >
                  <option value="">Day</option>
                  {dayOptions.map((opt) => (
                    <option key={opt.index} value={opt.value}>
                      {opt.title}
                    </option>
                  ))}
                </select>
                <span className="c-infoReq__select-arrows is-bday" aria-hidden>
                  <span className="c-infoReq__select-arrowIcon is-bday ic-bef ic-site-selector" />
                </span>
              </div>
              <div className="c-infoReq__seg is-bmonth">
                <select
                  className="c-infoReq__input c-infoReq__select-menu o-input o-fd o-fd-fade u-b-white u-t-nooutline"
                  id={CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_MONTH.id}
                  defaultValue={bDay.month}
                  {...register(
                    CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_MONTH.fieldName,
                    {
                      required: true
                    }
                  )}
                >
                  <option value="">Month</option>
                  {monthOptions.map((opt) => (
                    <option key={opt.index} value={opt.value}>
                      {opt.title}
                    </option>
                  ))}
                </select>
                <span className="c-infoReq__select-arrows is-bday" aria-hidden>
                  <span className="c-infoReq__select-arrowIcon is-bday ic-bef ic-site-selector" />
                </span>
              </div>
              <div className="c-infoReq__seg is-byear">
                <select
                  className="c-infoReq__input c-infoReq__select-menu o-input o-fd o-fd-fade u-b-white u-t-nooutline"
                  id={CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_YEAR.id}
                  defaultValue={bDay.year}
                  {...register(
                    CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_YEAR.fieldName,
                    {
                      required: true
                    }
                  )}
                >
                  <option value="">Year</option>
                  {yearOptions.map((opt) => (
                    <option key={opt.index} value={opt.value}>
                      {opt.title}
                    </option>
                  ))}
                </select>
                <span className="c-infoReq__select-arrows is-bday" aria-hidden>
                  <span className="c-infoReq__select-arrowIcon is-bday ic-bef ic-site-selector" />
                </span>
              </div>
            </div>
            {(errors?.bDay?.day ||
              errors?.bDay?.month ||
              errors?.bDay?.year) && (
              <label
                htmlFor={CUSTOMER_INFO_FIELD_KEYS.BIRTHDAY_DAY.id}
                className="error"
              >
                Fields are required.
              </label>
            )}
          </div>
          <div className="c-infoReq__field is-gender">
            <label
              htmlFor={CUSTOMER_INFO_FIELD_KEYS.GENDER.id}
              className="c-infoReq__label u-block u-required"
            >
              Gender
            </label>
            <div className="c-infoReq__select">
              <select
                className="c-infoReq__input c-infoReq__select-menu o-input o-fd o-fd-fade u-b-white u-t-nooutline"
                id={CUSTOMER_INFO_FIELD_KEYS.GENDER.id}
                defaultValue={gender}
                {...register(CUSTOMER_INFO_FIELD_KEYS.GENDER.fieldName, {
                  required: true
                })}
              >
                <option value="" disabled>
                  Choose your gender
                </option>
                {GENDER_OPTIONS.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <span className="c-infoReq__select-arrows" aria-hidden>
                <span className="c-infoReq__select-arrowIcon ic-bef ic-site-selector" />
              </span>
            </div>
            {errors[CUSTOMER_INFO_FIELD_KEYS.GENDER.fieldName] && (
              <label
                htmlFor={CUSTOMER_INFO_FIELD_KEYS.GENDER.id}
                className="error"
              >
                {REQUIRED_ERROR_MSG}
              </label>
            )}
          </div>
          <div className="c-infoReq__action">
            <button
              type="submit"
              className="c-infoReq__button o-btn o-fd o-fd-primary o-fd--block"
            >
              Update Your Information
            </button>
          </div>
          <div className="c-infoReq__dismiss">
            <span
              role="button"
              tabIndex={-1}
              className="c-infoReq__dismiss-link u-inline-block u-p-pointer"
              onClick={handleRemindLater}
            >
              Remind me later
            </span>
          </div>
        </form>
      </div>
    </HvModal>
  );
}
