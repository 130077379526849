import he from 'he';
import { isFinite, isEmpty, sortBy, property } from 'lodash';

// import RailsVars from '~/apps/railsVariables.js.erb';
import { SHOWROOM_TAG } from '~/utils/productTags';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { urlHandleFor } from '~/utils/urlHelper';

// const getNavSearchSuggestionUrl = (cols) =>
//   `${RailsVars.BASE_LAC_LONG_QUAN_URL}/collections/${cols}`;

const cleanCrumbData = (breadcrumbs = [], prop = 'name') =>
  isEmpty(breadcrumbs)
    ? null
    : breadcrumbs
        .filter((c) => !!c?.[prop])
        .map((c) => c?.[prop])
        .join(';');

const cleanSwatchesInfo = (raw = null, addData = []) => {
  if (isEmpty(raw)) return [];
  const pairs = raw.split(' ;;;; ').map((str) => {
    const init = str.replace('variant', '');
    const initLabel = init.substring(0, init.indexOf(':'));
    const groupId = defaultForUndefinedOrNull(
      initLabel.match(/\d+\b/)?.[0],
      ''
    );
    const prop = initLabel
      .substring(0, initLabel.length - groupId.length)
      .toLowerCase();
    return {
      groupId,
      [prop]: init.substring(init.indexOf(':') + 1)
    };
  });

  return pairs
    .reduce((acc, { groupId, ...others }) => {
      acc[groupId] = { ...acc[groupId], ...others };
      return acc;
    }, [])
    .filter((n) => !!n)
    .map((cv) => {
      const extendProps = defaultForUndefinedOrNull(
        addData.find((v) => v?.id === parseInt(cv?.id, 10)),
        {}
      );
      return { ...cv, ...extendProps };
    });

  // return pairs
  //   .reduce((acc, { groupId, ...others }) => {
  //     acc[groupId] = { ...acc[groupId], ...others };
  //     return acc;
  //   }, [])
  //   .filter((n) => !!n)
  //   .reduce((acc, { id, ...others }) => {
  //     const { id: _, ...extendProps } = defaultForUndefinedOrNull(
  //       addData.find((v) => v?.id === parseInt(id, 10)),
  //       {}
  //     );
  //     acc[id] = { ...others, ...extendProps };
  //     return acc;
  //   }, {});
};

const safeNumber = (val) =>
  isFinite(parseFloat(val)) ? parseFloat(val) : null;
const safeName = (str) => he.decode(defaultForUndefinedOrNull(str, ''));
const safeDate = (str) => (str ? new Date(str) : undefined);

const safePrices = ({ regular, promo, compare, final } = {}) => {
  const safeRegular = safeNumber(regular);
  const initPromo = safeNumber(promo);
  const safePromo = initPromo > 0 && initPromo < safeRegular ? initPromo : null;
  const initCompare = safeNumber(compare);
  const safeComparePrice = initCompare > safeRegular ? initCompare : null;
  const safeFinalPrice = final ? safeNumber(final) : safePromo || safeRegular;

  return {
    regularPrice: safeRegular,
    promoPrice: safePromo,
    comparePrice: safeComparePrice,
    finalPrice: safeFinalPrice
  };
};

const cleanVariantData = (records = [], isGrouped = false) =>
  records
    .filter(
      (r) =>
        defaultForUndefinedOrNull(r?.id, '').toLowerCase().indexOf('category') <
        0
    )
    .map((r) => {
      const extraData = JSON.parse(r?.additionalDataToReturn ?? '{}');
      const preferVar = defaultForUndefinedOrNull(
        extraData?.displayVariant,
        {}
      );

      const initialDisplayVariant =
        isGrouped && !isEmpty(preferVar)
          ? {
              id: parseInt(preferVar?.id, 10),
              name: safeName(preferVar?.name),
              ...safePrices({
                regular: preferVar?.regularPrice,
                promo: preferVar?.promoPrice,
                compare: preferVar?.comparePrice,
                final: preferVar?.finalPrice
              }),
              stock: preferVar?.stock,
              isSoldOut: preferVar?.isSoldOut,
              isFreeDelivery: preferVar?.isFreeDelivery,
              isFastShipping: preferVar?.isFastShipping,
              shipByDate: safeDate(preferVar?.shipByDate),
              dimensions: preferVar?.dimensions
            }
          : {
              id: parseInt(r?.id, 10),
              name: safeName(r?.name),
              ...safePrices({
                regular: r?.price,
                promo: r?.salePrice,
                compare: extraData?.comparePrice,
                final: extraData?.finalPrice
              }),
              stock: extraData?.stock,
              isSoldOut: r?.inStock === 'no',
              isFreeDelivery: r?.freeShipping === 'true',
              isFastShipping: extraData?.fastShipping,
              shipByDate: safeDate(r?.earliestDelivery),
              dimensions: null
            };

      return {
        ...initialDisplayVariant,
        brand: r?.brand,
        mainPdtId: extraData?.productId,
        link: urlHandleFor(r?.url),
        productName: defaultForUndefinedOrNull(
          extraData?.productName,
          safeName
        ),
        firstImgSrc: r?.image,
        secondImgSrc: r?.imageHover,
        tags: defaultForUndefinedOrNull(r?.tags, '')
          .split(',')
          .filter((t) => !!t),
        firmness: extraData?.firmness
          ? parseInt(extraData?.firmness, 10)
          : undefined,
        shipsIn: extraData?.shipsIn,
        totalSold: defaultForUndefinedOrNull(extraData?.totalSoldQty, 0),
        averageRating: defaultForUndefinedOrNull(extraData?.totalAvgRating, 0),
        swatchesInfo: cleanSwatchesInfo(
          r?.swatchesInfo,
          extraData?.displayColorVariants
        )
      };
    });

const cleanColorVariantsData = (swatchesInfo = [], mainPdtId = null) => {
  const list = swatchesInfo.map(
    (
      {
        id,
        productId,
        productName: pdtName,
        pdtRelativeUrl = '',
        productColor: pdtColor,
        image: pdtImgUrl,
        tags: productTags = [],
        ...otherProps
      },
      idx
    ) => {
      const position = !!productId && productId === mainPdtId ? -1 : idx;
      return {
        id,
        index: idx,
        listIndex: `item-${position}`,
        pdtId: productId,
        pdtName,
        pdtUrl: `/${pdtRelativeUrl}`,
        pdtImgUrl,
        pdtColor,
        isShowroomItem: productTags.includes(SHOWROOM_TAG),
        tags: productTags,
        ...otherProps
      };
    }
  );
  return sortBy(list, property('listIndex'));
};

const KLEVU_ABTEST_KEY = 'abtest-permanent-data';

export {
  KLEVU_ABTEST_KEY,
  cleanCrumbData,
  cleanVariantData,
  cleanColorVariantsData
};
